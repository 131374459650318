import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useOpenSeadragon, useViewerEvent } from 'use-open-seadragon';
import { useWindowSize } from '../hooks/useWindowSize';
import { Controls } from './Overlay';

const Container = styled.div`
	background: #000;
	position: relative;
`;

interface Viewer {
	id: string;
}

export const Viewer: FC<Viewer> = ({ id }) => {
	const windowSize = useWindowSize();
	const [viewerCursor, setViewerCursor] = useState('default');

	const dziHost = '/assets';
	const dziPath = `${dziHost}/images/sliced_files/${id}.dzi`;

	const osdSource = useMemo(() => {
		return {
			tileSource: dziPath,
		};
	}, [dziPath]);

	const osdOptions = useMemo(() => {
		return {
			springStiffness: 0.1,
			animationTime: 0.075,
		};
	}, []);

	const [ref, { isReady, goHome }] = useOpenSeadragon(osdSource, osdOptions);

	useViewerEvent('canvas-drag', () => setViewerCursor('grabbing'));
	useViewerEvent('canvas-drag-end', () => setViewerCursor('default'));

	useEffect(() => {
		if (isReady) {
			goHome();
		}
	}, [goHome, windowSize.height, isReady, windowSize.width]);

	return (
		<>
			<Container
				ref={ref}
				style={{
					width: windowSize.width,
					height: windowSize.height,
					cursor: viewerCursor,
				}}
			/>
			<Controls id={id} />
		</>
	);
};
