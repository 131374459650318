import React, { useEffect, useMemo, useState } from 'react';
import {
	Button,
	ButtonContainer,
	InfoBox,
	Overlay,
	ZoomInIcon,
	ZoomOutIcon,
	CloseIcon,
	MapIcon,
} from '@erm-tasku/components';
import { Map } from './Map';
import { useViewerContext, useZoom } from 'use-open-seadragon';
import { useTranslation } from 'react-i18next';
import { Data } from '../@types/types';
import { load as loadYaml } from 'js-yaml';

import '@erm-tasku/components/lib/bundle.css';

export const Controls = ({ id }: { id: string }) => {
	const [infoVisible, setInfoVisible] = useState<boolean>(false);
	const [mapData, setData] = useState<Data | null>(null);
	const { t } = useTranslation();
	const [currentZoom, setCurrentZoom] = useState<any>(0);
	const { zoomIn, zoomOut } = useZoom();
	const { viewer } = useViewerContext();

	function toggleInfo() {
		setInfoVisible((prev) => !prev);
	}

	async function fetchData() {
		return await fetch('/assets/map-data.yml')
			.then((res) => res.text())
			.then((text) => loadYaml(text));
	}

	useEffect(() => {
		fetchData().then((data) => {
			setData(data);
		});
	}, []);

	const currentData = useMemo(() => {
		return mapData?.[id];
	}, [mapData, id]);

	function updateZoom() {
		setCurrentZoom(viewer?.viewport?.getZoom());
	}

	useEffect(() => {
		if (viewer) {
			viewer.addHandler('zoom', updateZoom);

			return () => {
				viewer.removeHandler('zoom', updateZoom);
			};
		}
	});

	function handleZoomIn() {
		zoomIn();
	}

	function handleZoomOut() {
		zoomOut();
	}

	return (
		<Overlay>
			<InfoBox visible={infoVisible}>
				<p>{t(`${id}.caption`)}</p>
				<Map lat={currentData?.lat || 0} lng={currentData?.lng || 0} label={t(`${id}.location`)} />
			</InfoBox>
			<ButtonContainer>
				<Button onClick={handleZoomIn}>
					<ZoomInIcon aria-label={t('ui.zoomIn')} />
				</Button>
				<Button onClick={handleZoomOut} disabled={currentZoom > 0.8 ? false : true}>
					<ZoomOutIcon aria-label={t('ui.zoomOut')} />
				</Button>
				<Button onClick={toggleInfo}>
					{infoVisible ? <CloseIcon aria-label={t('ui.close')} /> : <MapIcon aria-label={t('ui.info')} />}
				</Button>
			</ButtonContainer>
		</Overlay>
	);
};
