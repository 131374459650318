import React from 'react';
import { ViewerProvider } from 'use-open-seadragon';
// https://github.com/stephenwf/use-open-seadragon/tree/master/src/hooks
import { GlobalStyles } from '@erm-tasku/styles';

import './i18n';
import { Viewer } from './components/Viewer';
import { FullscreenToggle, GlobalButtonContainer } from '@erm-tasku/components';
import { useTranslation } from 'react-i18next';

export default function App() {
	const { t } = useTranslation();
	const urlParams = new URLSearchParams(window.location.search);
	const id = urlParams.get('id') || 'P0073f';

	return (
		<>
			<GlobalStyles />
			<GlobalButtonContainer>
				<FullscreenToggle enterLabel={t(`ui.fullscreen`)} exitLabel={t(`ui.fullscreenExit`)} />
			</GlobalButtonContainer>
			<ViewerProvider>{id && <Viewer id={id} />}</ViewerProvider>
		</>
	);
}
