import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	position: relative;

	width: 100%;
	height: 100%;

	margin-top: 0;
	margin-bottom: -4vh;

	svg {
		width: 100%;
		height: 100%;

		g {
			width: 100%;
			height: 100%;
		}

		polygon, path {
			fill: #00AEEF;
		}

		.map-stroke {
			fill: none;
			stroke: var(--blue);
			stroke-width: 2;
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-miterlimit: 4;
		}

		.map-marker {
			fill: #fff;
		}
	}
`;

interface Map {
	lat: number;
	lng: number;
	label: string;
}

export const Map: FC<Map> = ({ lat, lng, label }) => {
	var xMin = 286962.54;
	var xMax = 814566.728;
	var yMin = 6302756.647;
	var yMax = 6674038.871;
	var mapWidth = 727.8;
	var mapHeight = 514.3;

	var x = map(lng, xMin, xMax, 0, mapWidth);
	var y = map(lat, yMin, yMax, mapHeight, 0);

	return (
		<Container>
			<svg
				version="1.1"
				x="0px"
				y="0px"
				width="727.8px"
				height="514.3px"
				viewBox="100 50 550 400"
				enableBackground="new 0 0 727.8 514.3"
				xmlSpace="preserve"
			>
				<title>{label}</title>
				<rect x="0" y="0" fill="none" width="727.8" height="514.3" />

				<g>
					<path fillRule="evenodd" clipRule="evenodd" d="M464,317.5l0.9,4.3l1.5-4.2l-0.3-9.4l1.9-6.7l1.8-1.8l-0.4-13.1l1.7-2.8l-1-5l-3.4-3.8l-5.1-1.8l-6.4,2.8
						l-0.3,7.2l-3.6,3.1l0.1,4l4.8,7.5l3.2,9.2l3.2,1.9l-0.6,3.5L464,317.5z M542.6,265.1L542.6,265.1L542.6,265.1L542.6,265.1z
						M541.3,264.1l0.4,0.3l0.3,0.2L541.3,264.1z M575.5,383l2.1,5.3l-1.5,6.4l-4.9,2.7l3.4,8.9l-8.1-4.2l-5.3,0.3l-6.5-1.5l-7.1-3.3
						l-0.3-3l-10.5-4l-3.5,3l0,3.5l-7.2,3l-8.1,1.5l-4.9,6l0.3,3.1l-3.3-3.7l-1.7,3.1l-4.9-1.5l-2.8-7.8l-5.6,0.7l-9.4-2.6l-3-3.4
						l-1.5-6.8l-5.1-4.5l2-4.1l-5.3-2.7l-0.6-2.7l-4.8-2.3l-4.5-0.1L465,360l-4.8-1.5l-3,2.1l-11.8-2.6l-2.8-6.1l-4.3-2.9l-2.7,3.3
						l-5.4-4.1l-4.8-2l-0.6-4.6l-5.1,0.8l-5.8-3.9l1.9-1.5l-4.9-3.6l-3.7-0.3l-6.5-7.7l-1.7,3.4l3.3,3.9l0,6.2l-3.8,0.1l-2.6-4.3
						l-0.9-8.1l-9.1-0.4l-2.3,1.8l-4.5,7.6l-5.9,1.4l-9.5,5.9l-2-2.9l-5.4,1.4l0.1,2.7l-4.2,2.9l-4-1.9l-1.3,2.7l-3.6-3.1l-5.9,4.9
						l-4.2,0.9l-1.4,2.6l1.2,4.3l-4,2.4l-4.5-2l2.8-11.5l3.9-8l3.2-9.9l-1.8-5.9l1.8-2.2l-2-12.9l2.6-8.5l5-2.9l2.9-4l-2.7-7.9l-3.7-2.8
						l-7.7-2.5l-8.9,1.4l-2.1,12.6l-2.2,3.7l-6.2,0.5l-5.3,4.6l-3.9-3.3l-2.4-6l-9.9-4.6l-2.6,1.4l-3.3-5.8l-1.9,3.2l-5.7-3l-5.4-13
						l1.1-10l-1.4-3.8l-1.8,2.7l-6.8-7.2l1.3-3.2l-5.9,3l1.6-7.8l-2-4.6l-0.6-7.3l6.5-3.3l-3.7-2.3l0.4-2.5l3.7,2.1l10.6-3.3l3.5,1.9
						l3.8-2.2l-6.1-5.8l-3,1.8l-6.7-1.4l-4.5,2.5l-7-0.3l2.2-6l3.7-0.7l-4.9-9.3l-4-5.1l0.4-4.1l10.4-2l3.4-2.3l-1.1-2.2l6.3-0.1l-4-4.7
						l0.5,4.3l-4.1-4l0.4,4.2l-2.4,2.2l-3-3.1l-5-1l-3-5.8l3.1,0.7l-0.7-8.6l3.2,0.6l3.1-3.6l2.4,0.3l-2.4-11l-1.6-1.5l1.3-8.4l2.1,1.9
						l5.3-0.8l4.4-3.8l3.5,2.8l3.6-3.3l-1-3.6l2.6,0.6l4.7-3l5,3l4.4-0.1l7.1-3.1l4.8,3.3l2-5.4l-1.3-3.7l-4.9-4.6l-0.4-4.6l9.3,3.5
						l3.8,3.7l3.2-0.5l-0.8-5l-3.8-3l3.8,0.3l8.3-4.6l2-7.5l7.9,0.6l6.1,4.7l2.5-3.8l7.1,5.1l1.5-3.2l-2.7-2.1l8.9,2.6l3-0.3l2.9-6.2
						l-2.4-2.9l-1.2-9.4l7.4,2.8l2.8,7.9l3.5,1l9.8-2.5l4.6,3.5l2.2-3.6l-2.5-5.2l5.2,5.5l4.9-3.8l0,2.5l5.1,2.5l6.8,0.5l2.6-4.2
						l5.8-0.7l1.8-3.7l-5.3-10.6l0.3-5.4l1.6,4l7,3.3l1.2,3.2l3.8,2.2l3.1-2.5l0.4-3.3l-2.4-2.3l0.9-8.1l5.5,4.2l1,10.1L438,95l4.4-2.5
						l-0.7-7.2l4.6,3.6l-1,2.6l5.2-0.5l-1.3-7.5l2.9,3.6l4.5-2.4l3.7,6.4l12.7-0.6l5.5,4.6l7.1,0.8l6.9,4.5l5.8-1.9l0.7-3.9l11.9,4.5
						l0.3,2.4l15.5,9.8l8.8,0.4l13.6-2.2l14.7-0.9l15,3.9l11.3-1.3l6,3.4l8.1-1.5l4.2-3.1l5.2-8.1l6.4,4.5l6.1,6.2l1.3,4.8l-5.9,4.6
						l-0.4,5.7l-6,0.2l-6.2,4.8l-4.2,4.7l-0.7,8l-2.2,5.5l-3.8,4.7l-2.3,15.1l-1.4,0.3l-0.5,6.4l-12.7-2.1l-16.6,1.7l-12,3.2l-7.8,3.6
						l-7.6,6.2l-3.8,5.9l-1.6,10.4l2.8,6.5l5.5,1.8l4.2,3.8l5.9,8.4l1.3,7.8l3.1,4.2l0.4,5.5l2.9,9l1.1,11.9l-0.5,3l4.1,4.1l0.8-2.8
						l5.2,1.7l6.3,4.5l-2.1,0.7l2.1,8.2l-1.5,2.4l5.6,8.2l-0.7,2.2l3,8.9l2.1,3.3l1,5.9l6.6,3.7l-1.5,7.2l3.2,4.7l2.9-2.6l3.4,2.5
						l-0.7,6l-2.3,0.9l2.5,4.1l7.4,4.8l3.8-0.3l0.2,5.1l-3,4.7l-2.9,1.1l-10-1.3l-6.5,2.9L587,364l-2.8,0.3l2.9,4.8l0.3,7.4l-2.8-1.9
						l-6.6,3.8L575.5,383z"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="226.5,252.2 224.4,255.3 225.5,258.3 229.3,259.2 238.4,264.9 238.2,268.8 233.2,267.7 233.9,272.6 
						227.9,268.6 226.9,264.8 224.4,273.7 220,272.7 221,280 217.7,279 216.3,282.5 213.1,282.4 211.1,278.7 209.3,281.4 211.2,284.3 
						208.1,286.7 207.7,291.6 202.2,289.6 199.1,297.7 190.2,294.9 189,297.3 192.9,303.9 190.3,305.4 181.9,299.8 180,303.5 
						177.2,303.8 175.2,299.1 172.8,304.1 169.5,299.9 166.1,304.3 160.6,303.6 153.8,307.9 152,314.1 153.1,317.2 149.5,329.5 
						145.9,335.7 147.1,340 142.9,342.6 135.3,350 132.7,350.3 129.1,344 129.2,340.1 132.3,339.2 136.7,327.3 139.7,323.3 144.6,327 
						149,312.9 143.7,312.6 143.4,310.6 136.7,309.4 133.6,299.6 123.8,297.9 118.4,292.8 119.7,288 123.6,290.6 124.5,287.9 
						121.7,283.4 128.8,286 127.2,281.3 131,284.6 130.7,279.8 134,280.5 131.8,275.3 128.9,274 128.5,270.8 124.4,263.2 126.3,261.4 
						126.4,256.4 134.6,259.3 138.9,272 142.3,271.2 142.5,262.5 149.2,257.8 149.8,252.6 152.3,254.2 152.7,261.2 156.9,261.8 
						158.7,257.1 156.6,253.7 156.6,249.1 158.5,249.9 172.7,247.2 175.2,244.2 177.1,238.5 180.3,240 183.3,246.5 187.6,248.4 
						189.5,245.1 194.1,249.3 198.1,241.4 204.3,241.4 210,242.9 219.7,251.3 225.4,253.4 227.6,250.5 221.3,247.9 220,245.3 
						222.4,241.5 226.3,241.4 225.4,236.1 230,232.7 242.4,238.5 242.1,243.3 246.1,250.5 246.5,255.4 242.6,259 240.4,253.1 
						238.9,256.5 236.5,253.8 233.2,258 227.7,250.6"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M199.5,210.3l-4.9,3.3l2.8,2.4L199.5,210.3z M182.4,168.8l5.7,1l3.1,2.2l-1,4.4l1.1,4.8l10.5,3l0.6-1.2
						l6.4,4.3l1.5-1.2l0.2,6.3l6.6,7.5l-0.3,2.2l3.7,6.6l-3.6,1.9l-2-1.8l-6.2-0.3l-0.2,2.4l-4.4-2.2l-2.8,2.1l4.7,0.5l-6.4,8.1
						l-6.1-6.2l-0.6,5.2l-5.1,11.4l-7.7,1.6l-6.6-2.3l-1.5-2l2.7-2.8l-2.8-3.4l0.6-4l-2.2-1.7l1.3-5.6l-2-6.2l-4.7-4.8l-8.9,2.1l-7-0.1
						l-2.9-4l-7-2.5l0.1-2.9l12.6,1.4l8.5-2.1l4,2.2l4.1-1.6l1.1-3.3l3.5,0.3l-2.4-4.6l4.5-0.6l-1.1-4.4l7.2-0.6L182.4,168.8z"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="230.5,175.9 235.5,178.4 236.7,181 241.5,178.3 246.3,185.3 238.5,188.3 238.3,190.6 234.2,186.4 
						231.6,189.4 224.9,182.2 225.7,177.6"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="291.8,128 295,130.2 295.8,135.6 292.9,135.2 290.8,131.7 290.2,137.6 284.3,130.6 289.7,130.5"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="335.4,90.2 339.2,100.6 333.5,97.9"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="295.4,315.1 297,315.5 294.5,323.7 291.4,320.9 292.5,315.7"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="122.6,275.2 125.5,277.5 118.8,279 117,276.6 123,276.8"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="172.7,310.3 175,315.6 172.5,318.4 171.2,315.6"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="231.8,365.9 235.2,371.7 230.3,371.7"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="371.6,83.4 374.7,84.8 376.4,89.2"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="356.5,92.2 354.8,95.3 353.2,94"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="581,272 581,272 581,272"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="120.2,275.9 120.2,275.8 120.2,275.9"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="581.1,271.4 582.5,275.1 580,276 577.3,272"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="247.8,140.4 247.8,140.4 247.8,140.3"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="244.6,136.8 249.8,140.8 245.8,139.5"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="389.9,93.8 391.6,93.7 392.8,96"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="380.1,89.1 381.8,91.4 380.3,90.5"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="403.5,102.3 404.1,104 402.5,103.1"/>
					<polygon fillRule="evenodd" clipRule="evenodd" points="395.4,98.5 395.4,99.6 394.2,98.4"/>
				</g>

				<g transform={`translate(${x - 15},${y - 26})`}>
					<polygon className="map-marker" points="30,0 15,26 0,0" />
				</g>
			</svg>
		</Container>
	);
};

function map(value: number, from1: number, to1: number, from2: number, to2: number) {
	return ((value - from1) / (to1 - from1)) * (to2 - from2) + from2;
}
